// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-flavor-page-js": () => import("./../../../src/templates/FlavorPage.js" /* webpackChunkName: "component---src-templates-flavor-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/NotFoundPage.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */)
}

